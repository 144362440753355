import Vue from "vue";
import VueRouter from "vue-router";
import * as views from "../views";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:tour/:panorama?",
    name: "Tour",
    component: views.Krpano,
    props: true,
  },
  // {
  //   path: "*",
  //   name: "Tour",
  //   component: views.Krpano,
  // },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

window.popStateDetected = false;
window.addEventListener("popstate", () => {
  window.popStateDetected = true;
});

export default router;
