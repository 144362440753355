<template>
  <div :class="`modal__container ${open ? 'modal__container_open' : ''}`">
    <div class="modal__inner">
      <a
        class="btn--menu modal--close gui__element gui__element--top-right"
        href="#!"
        @click.prevent="$emit('close')"
      >
        <img :src="`/assets/img/icon-close.svg`"
      /></a>

      <div class="modal__content">
        <div
          v-if="
            open &&
            content.hasVideo &&
            content.videoUrl &&
            content.videoType.value
          "
          class="modal__content__top"
        >
          <iframe
            v-if="content.videoType.value.toLowerCase() == 'youtube'"
            :src="`https://www.youtube.com/embed/${parseYoutubeLink(
              content.videoUrl
            )}?controls=0`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
            allowfullscreen
          ></iframe>
          <iframe
            v-if="content.videoType.value.toLowerCase() == 'vimeo'"
            title="vimeo-player"
            :src="`https://player.vimeo.com/video/${parseVimeoLink(
              content.videoUrl
            )}`"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>

        <div
          :class="`swiper ${
            content.hasVideo || !content.images || content.images.length < 1
              ? 'hidden'
              : ''
          } modal__content__gallery modal__content__top`"
        >
          <div class="swiper-wrapper">
            <div
              v-for="(image, index) in content.images"
              :key="index"
              class="swiper-slide img__wrapper swiper-no-swiping"
              @click="tinyIndex = index"
            >
              <img :src="image.thumbnail" alt="Modal image" />
            </div>
          </div>

          <div
            :class="`swiper-pagination ${
              !content.images || content.images.length <= 1 ? 'hidden' : ''
            }`"
          ></div>

          <div>
            <div class="swiper-navigation swiper-button-prev"></div>
            <div class="swiper-navigation swiper-button-next"></div>
          </div>
        </div>

        <div
          v-if="
            !content.hasVideo ||
            (content.hasVideo &&
              (content.title ||
                content.content ||
                content.externalLinkTitle ||
                content.externalLink ||
                content.attachedFileTitle ||
                content.attachedFileLink))
          "
          :class="`modal__content__text ${
            content.hasVideo || (content.images && content.images.length > 0)
              ? 'has-top-content'
              : ''
          }`"
        >
          <h2 v-if="content.title">
            {{ content.title }}
          </h2>
          <p v-if="content.content" v-html="content.content"></p>

          <div class="modal__content__actions">
            <a
              class="button button--secondary"
              v-if="content.attachedFileLink"
              :href="content.attachedFileLink"
              download
            >
              {{
                content.attachedFileTitle
                  ? content.attachedFileTitle
                  : "Download"
              }}
            </a>
            <a
              class="button button--secondary"
              v-if="content.externalLink"
              :href="content.externalLink"
              rel="noopener noreferrer"
              target="_blank"
            >
              {{
                content.externalLinkTitle
                  ? content.externalLinkTitle
                  : "Open external link"
              }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <Tinybox
      v-if="content.images"
      v-model="tinyIndex"
      :images="content.images.map((i) => i.url)"
      no-thumbs
      loop
    />
    <div class="modal__outer" @click="$emit('close')"></div>
  </div>
</template>

<script>
import { parseYoutubeLink, parseVimeoLink } from "@/helpers";
import Swiper, { Navigation, Pagination } from "swiper";
// import "@SwiperBundleCss";
import Tinybox from "vue-tinybox";

export default {
  name: "Modal",
  components: {
    Tinybox,
  },
  props: {
    content: Object,
    open: Boolean,
    close: Function,
  },
  data() {
    return {
      tinyIndex: null,
      activeIndex: 0,
    };
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.$emit("close");
      }
    });

    Swiper.use([Navigation, Pagination]);
    new Swiper(".swiper", {
      spaceBetween: 24,
      // grabCursor: true,
      slidesPerView: "auto",
      // centeredSlides: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        type: "bullets",
      },
    });
  },
  methods: {
    parseYoutubeLink: parseYoutubeLink,
    parseVimeoLink: parseVimeoLink,
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/lib/swiper-bundle.min.css";

.modal {
  &__container {
    display: grid;
    position: absolute;
    background: rgba($color-black, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
    opacity: 0;
    visibility: hidden;
    transition: opacity $basic-transition-500 0.1s;

    &_open {
      pointer-events: all;
      opacity: 1;
      visibility: visible;

      .modal__inner {
        opacity: 1;
        top: unset;
        opacity: 0.9999;
        transform: translateX(-50%);
        visibility: visible;

        width: 100%;
        height: 100%;

        .modal__content {
          opacity: 1;

          &__gallery {
            opacity: 1;
          }

          .flicking__dots {
            opacity: 1;
          }
        }

        @include for-tablet-landscape-up {
          width: 50%;
          height: auto;
          max-height: 80%;
          max-width: 800px;
          // min-height: 500px;
          border-radius: 16px;
          bottom: unset;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .modal__outer {
        pointer-events: all;
        opacity: 0.3;
      }
    }
  }

  &__inner {
    overflow: hidden;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 1001;
    position: absolute;
    opacity: 0;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 20px;
    height: 20px;

    transition: height $basic-transition-500 0.1s,
      width $basic-transition-500 0.1s, opacity $basic-transition-500 0.1s;

    .modal__content {
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity $basic-transition-300 0.5s;
      display: flex;
      flex-direction: column;
    }

    font-size: 16px;
    line-height: 1.4;
  }

  &__outer {
    pointer-events: all;
    cursor: pointer;
    opacity: 0;
  }

  &--close {
    position: absolute;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.9rem;
    height: 5.9rem;
    background: transparent;

    backdrop-filter: blur(10px);
    background: rgba(lighten($color-black, 30%), 0.8);

    img {
      width: 28px;
      height: 28px;
      transition: transform $basic-transition-300;

      filter: invert(100%) sepia(7%) saturate(0%) hue-rotate(157deg)
        brightness(105%) contrast(108%);
    }

    &:hover img {
      transform: rotate(90deg);
    }
  }

  &__content {
    overflow: hidden;

    text-align: left;

    &__top {
      overflow: hidden;
      position: relative;
      background: $color-accent;
      height: 45%;
      padding-top: 56.5%;
      height: 0;

      iframe,
      object,
      embed,
      .swiper-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__text {
      padding: 24px;
      height: 100%;
      display: grid;
      grid-template: auto 1fr / 1fr;
      overflow-y: auto;

      &.has-top-content {
        height: 100%;
        padding: 24px;
      }

      h2 {
        font-size: 2.8rem;
        padding-right: 5.9rem;
      }

      p {
        margin-top: 2rem;
        overflow-y: auto;
        padding-right: 3.6rem;
        line-height: 2.6rem;
        margin-bottom: 18px;
      }

      @include for-tablet-landscape-up {
        padding: 56px 24px 56px 56px;

        &.has-top-content {
          padding: 36px 24px 24px 60px;
        }
      }
    }

    &__gallery {
      opacity: 0;
      transition: transform $basic-transition-300;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      @include for-tablet-landscape-up {
        flex-direction: row;
        gap: 1.6rem;
      }

      a.button {
        margin-top: 1.6rem;
        color: $color-white;
        display: inline-block;
        font-weight: bold;
        padding: 12px 24px;
        text-decoration: none;
        transition: background-color $basic-transition-200;

        &--secondary {
          background-color: $color-secondary;

          &:hover {
            background-color: darken($color-secondary, 10%);
          }
        }
      }
    }
  }
}

.swiper {
  .img__wrapper {
    width: 100%;
    height: 100%;
    cursor: pointer;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      transition: all $basic-transition-200;
    }
  }

  &-pagination {
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    display: grid;
    grid-auto-flow: column;
    grid-gap: 12px;
    backdrop-filter: blur(10px);
    background: rgba($color-white, 0.4);

    border-radius: 0;
    z-index: 10;
    padding: 1.2rem 1.6rem;
    width: unset;

    cursor: pointer;
  }

  &-navigation {
    position: absolute;
    top: unset;
    bottom: 0;
    background: rgba(lighten($color-black, 30%), 0.8);
    backdrop-filter: blur(10px);
    width: 3rem;

    &.swiper-button-next {
      right: 0;
      left: unset;
    }

    &.swiper-button-prev {
      right: 3.3rem;
      left: unset;
    }
  }
}
</style>
