<template>
  <div class="loader">
    <div class="loader-wrapper">
      <div class="loader-wrapper--1"></div>
      <div class="loader-wrapper--2"></div>
      <div class="loader-wrapper--4"></div>
      <div class="loader-wrapper--3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style lang="scss" scoped>
.loader {
  background-color: rgba($color-white, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1002;

  display: grid;
  place-content: center;

  opacity: 1;
  transition: opacity $basic-transition-300;

  &-wrapper {
    display: grid;
    place-content: center;
    grid-template: 1fr 1fr/ 1fr 1fr;
    grid-gap: 4px;

    div {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: $color-primary;
    }

    @for $i from 1 through 4 {
      &--#{$i} {
        animation: changeBackground 1.6s steps(1) ($i * 0.4s) infinite;
      }
    }

    @keyframes changeBackground {
      0% {
        background-color: $color-secondary;
      }

      25% {
        background-color: $color-primary;
      }

      66% {
        background-color: $color-primary;
      }

      100% {
        background-color: $color-primary;
      }
    }
  }
}
</style>
