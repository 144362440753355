<template>
  <div
    class="krpano__wrapper"
    v-if="$route.params.tour"
    @keydown.esc="modal = false"
  >
    <Loader v-if="loading" />
    <TutorialScreen v-if="showTutorial" @close="showTutorial = false" />
    <Modal
      class="modal"
      :open="modal"
      :content="modalContent"
      @close="modal = false"
    />

    <transition name="fade">
      <div class="gui" v-if="!modal && !showTutorial">
        <div class="gui__corner gui__element gui__element--top-left">
          <a :href="`/${$route.params.tour}`" class="icon-link">
            <img
              class="icon icon--white icon--rotate"
              src="/assets/img/icon-arrow.svg"
              alt="Pijl icoon"
            />
            <span>{{ $page.back }}</span>
          </a>
        </div>

        <div
          v-if="firstVideoPano && firstVideoPano !== currentPano"
          class="gui__corner gui__webvr gui__element gui__element--top-right"
        >
          <a
            href="#!"
            @click.prevent="loadPano(null, firstVideoPano)"
            class="icon-link"
          >
            <img
              class="icon icon--secondary"
              src="/assets/img/icon-video.svg"
              alt="Video icoon"
            />
            <span>{{ $page.open_video }}</span>
          </a>
        </div>
        <div
          v-if="firstVideoPano && firstVideoPano === currentPano"
          class="gui__corner gui__webvr gui__element gui__element--top-right"
        >
          <a
            href="#!"
            @click.prevent="loadPano(null, firstPano)"
            class="icon-link"
          >
            <img
              class="icon icon--secondary"
              src="/assets/img/icon-photo.svg"
              alt="Foto icoon"
            />
            <span>{{ $page.close_video }}</span>
          </a>
        </div>

        <VideoControls
          v-if="firstVideoPano && firstVideoPano === currentPano"
        />

        <div
          v-if="webVRCapable && !webVRActive"
          :class="`gui__corner gui__webvr gui__element ${
            firstVideoPano
              ? 'gui__element--bottom-right'
              : 'gui__element--top-right'
          }`"
          :style="firstVideoPano === currentPano ? 'margin-bottom: 1rem;' : ''"
        >
          <a href="#!" @click.prevent="startWebVR" class="icon-link">
            <img
              class="icon icon--secondary"
              src="/assets/img/icon-vr.svg"
              alt="Pijl icoon"
            />
            <span>{{ $page.enter_vr }}</span>
          </a>
        </div>
        <div
          v-if="webVRActive"
          :class="`gui__corner gui__webvr gui__element ${
            firstVideoPano
              ? 'gui__element--bottom-right'
              : 'gui__element--top-right'
          }`"
        >
          <a href="#!" @click.prevent="exitWebVR" class="icon-link">
            <img
              class="icon icon--secondary"
              src="/assets/img/icon-vr.svg"
              alt="Pijl icoon"
            />
            <span>{{ $page.exit_vr }}</span>
          </a>
        </div>

        <div
          v-if="currentPano"
          class="gui__title gui__element gui__element--bottom-left"
        >
          <h1>{{ currentTour.title }}</h1>
          <h2>{{ currentPano.title }}</h2>
          <ReadMore
            v-if="currentPano.description"
            :text="currentPano.description"
            :max="200"
          />
        </div>

        <Dots
          v-if="
            panos &&
            panos.length > 1 &&
            !webVRActive &&
            currentPano.type.value === 'pano'
          "
          class="gui__element gui__element--right-center"
          :panos="panos"
          :active="currentPano"
          :loadPano="loadPano"
        />
      </div>
    </transition>
    <div id="krpano"></div>
  </div>
</template>

<script>
import Vue from "vue";
import Modal from "@/components/Modal.vue";
import Dots from "@/components/Dots.vue";
import Loader from "@/components/Loader.vue";
import Hotspot from "@/components/Hotspot.vue";
import ReadMore from "@/components/ReadMore.vue";
import VideoControls from "@/components/VideoControls.vue";
import TutorialScreen from "@/components/TutorialScreen.vue";
import virtualTourApi from "@/api";
import {
  addVideoHotspot,
  addLeaveVideoHotspot,
  addExitVrHotspot,
  addControlHotspots,
  addVrHotspots,
  removeVrHotspots,
  loadHeroHotspot,
  addHeroVrHotspots,
} from "@/helpers/vr";
import { isTouchDevice } from "@/helpers";

export default {
  name: "Krpano",
  components: {
    Modal,
    Dots,
    Loader,
    Hotspot,
    ReadMore,
    TutorialScreen,
    VideoControls,
  },
  data() {
    return {
      loading: true,
      modal: false,
      modalContent: {},
      openHero: false,
      webVRCapable: false,
      webVRActive: false,
      arSimulation: false,
      panos: [],
      panorama: this.$route.params.panorama
        ? this.$route.params.panorama
        : null,
      currentTour: null,
      currentPano: null,
      previousPano: null,
      firstPano: null,
      firstVideoPano: null,
      showTutorial: localStorage.getItem("tutorialShown") ? false : true,
      client: {
        title: process.env.VUE_APP_CLIENT_TITLE,
        url: process.env.VUE_APP_CLIENT_URL,
      },
    };
  },
  mounted() {
    // Tour using qr
    if (this.$route.query.gyro === "true") {
      this.arSimulation = true;
    }

    if (this.$route.params.tour) {
      // Tour selected - retrieve panos & init krpano
      this.fetchPanos();
    }
    this.initWindow();
  },
  beforeRouteUpdate(to, from, next) {
    const IsItABackButton = window.popStateDetected;
    window.popStateDetected = false;
    if (IsItABackButton) {
      let previousPano = this.panos.find((p) => p.slug == to.params.panorama);
      if (previousPano && this.currentPano !== previousPano) {
        this.currentPano = previousPano;
        window.krpanoObj.call(
          `loadscene('${previousPano.name}', null, MERGE, BLEND(0.5));`
        );
        // this.sendGtmView(
        //   `${this.currentPano.title} - Virtual tour`,
        //   `/${this.$locale}/virtual-tour/${this.currentTour.slug}/${this.currentPano.slug}`
        // );
      } else {
        history.back();
      }
    }
    next();
  },
  methods: {
    initWindow() {
      // Init loader
      window.startLoading = () => {
        this.loading = true;
        if (this.webVRActive && !this.openHero && !krpanoObj.xml.scene) {
          krpanoObj.actions.loadscene(
            this.panos[0].name,
            null,
            "MERGE",
            "BLEND(0.5)"
          );
        }
      };
      window.stopLoading = () => {
        this.loading = false;
        // if (this.webVRActive) {
        //   this.startWebVR();
        // }
        if (this.webVRActive && !this.openHero) {
          addExitVrHotspot();
          addControlHotspots();

          if (this.firstVideoPano) {
            if (this.currentPano.id !== this.firstVideoPano.id) {
              addVideoHotspot(this.firstVideoPano);
            } else {
              addLeaveVideoHotspot(this.firstPano);
            }
          }
        }
        if (this.openHero) {
          addHeroVrHotspots();
        }
      };

      window.popStateDetected = false;
      window.addEventListener("popstate", () => {
        window.popStateDetected = true;
      });

      // Web VR init
      window.setVrReadyStatus = (vrStatus) => {
        this.webVRCapable = vrStatus;
      };

      window.loadHeroHotspot = loadHeroHotspot;

      // Init hotspots
      window.loadHotspot = async (spot, pano = null) => {
        if (!pano) pano = { name: this.currentPano.name };
        console.log();
        let hotspot = (
          await virtualTourApi.getHotspot(
            this.currentTour.id,
            pano.name,
            spot.name
          )
        ).data;
        if (hotspot.linkedScene || hotspot.linkedPano) {
          let linkedPano = (
            await virtualTourApi.getPano(
              this.currentTour.id,
              hotspot.linkedPano
                ? hotspot.linkedPano.panoName
                : hotspot.linkedScene
            )
          ).data;
          this.loadHotspotStyle(spot, hotspot, linkedPano);
        } else {
          this.loadHotspotStyle(spot, hotspot, null);
        }
      };

      // Handle VR hotspot click
      window.handleHotspotClick = (type, title, slug, name) => {
        switch (type) {
          case "navigation":
            if (this.openHero) {
              this.openSlugTour(slug);
            } else {
              this.loadPano(null, { title: title, name: name, slug: slug });
            }
            break;
          case "exit_vr":
            if (this.openHero) {
              location.reload();
            } else {
              this.exitWebVR();
            }
            break;
          case "next_vr":
            this.openFollowingTour("next");
            break;
          case "prev_vr":
            this.openFollowingTour("prev");
            break;
          case "aerial_vr":
            var rawFile = new XMLHttpRequest();
            var heroTour = "";
            rawFile.open("GET", "/assets/hero-vtour/tour.xml", false);
            rawFile.onreadystatechange = function () {
              if (rawFile.readyState === 4) {
                if (rawFile.status === 200 || rawFile.status == 0) {
                  heroTour = rawFile.responseText;
                  let parser = new DOMParser();
                  let xmlDoc = parser.parseFromString(heroTour, "text/xml");
                  let path = "panos/hero.tiles/";
                  let pathNew = "/assets/hero-vtour/panos/hero.tiles/";
                  heroTour = xmlDoc.documentElement.querySelector(
                    "[name='scene_photo']"
                  );

                  heroTour
                    .querySelectorAll("[linkedtour]")
                    .forEach((hotspot) => {
                      hotspot.setAttribute(
                        "onloaded",
                        "load_hero_hotspots(get(hotspot[get(name)]));"
                      );
                    });
                  heroTour = heroTour.innerHTML.replace(
                    new RegExp(path, "g"),
                    pathNew
                  );
                }
              }
            };
            rawFile.send(null);
            this.openHero = true;
            window.krpanoObj.call(`loadxml('<krpano>${heroTour}</krpano>');`);
            break;
          default:
            break;
        }
        if (this.openHero && type !== "aerial_vr") {
          this.openHero = false;
        }
      };
    },
    async fetchPanos() {
      virtualTourApi.getPanosForTour(this.$route.params.tour).then((res) => {
        if (!res.data.panos) {
          window.location.href = `/${this.$route.params.tour}`;
        } else {
          this.loadTour(res.data);
        }
      });
    },
    loadTour(tour) {
      this.currentTour = tour;
      this.$store.state.currentTour = this.currentTour;

      this.panos = tour.panos;
      this.firstPano = this.panos.find((p) => p.type.value === "pano");
      this.firstVideoPano = this.panos.find((p) => p.type.value === "video");

      if (this.panorama && !this.webVRActive) {
        // Pano selected - init Krpano with certain pano
        let panoToShow = this.panos.find((p) => p.slug == this.panorama);
        this.currentPano = panoToShow;

        if (panoToShow) {
          // Pano is new so let load on init
          this.initTour(panoToShow);
          return;
        }
      }
      // No start pano selected - init Krpano from start
      this.currentPano = tour.panos[0];
      this.initTour();
      return;
    },
    initTour(startPano) {
      // Init krpano with the current tour
      let xml = `/assets/vtours/${
        this.currentTour.slug
      }/tour.xml?h=${+new Date()}`;
      let { embedpano } = window;

      if (window.krpanoObj) {
        removepano("krpanoObject");
      }
      embedpano({
        xml: xml,
        id: "krpanoObject",
        target: "krpano",
        html5: "only+webgl",
        mobilescale: 1.0,
        wmode: "opaque",
        // passQueryParameters: true,
        consolelog: true,
        mwheel: true,
        onready: (krpano) => {
          window.krpanoObj = krpano.get("global");
          if (isTouchDevice()) {
            krpanoObj.control.mouse = "drag";
            krpanoObj.control.touch = "drag";
          } else {
            krpanoObj.control.mouse = "follow";
            krpanoObj.control.touch = "follow";
          }
        },
        initvars: {
          basePath: "/assets/js",
          partialPath: "/assets/js/partials",
          pluginPath: "/assets/js/plugins",
          assetPath: "/assets/js/assets",
          dev: process.env.NODE_ENV === "development" || this.$route.query.webvr === 'true',
          ar: this.currentPano.ar && this.arSimulation,
        },
      });

      if (startPano) {
        this.loadPano(null, startPano);
      } else if (!this.panorama) {
        this.panorama = this.currentPano.slug;
        this.$router.push({
          path: `/${this.currentTour.slug}/${this.panorama}`,
        });
      }

      // if (!startPano) {
      //   this.sendGtmView(
      //     `${this.currentPano.title} - Virtual tour`,
      //     `/${this.$locale}/virtual-tour/${this.currentTour.slug}/${this.currentPano.slug}`
      //   );
      // }
    },
    loadPano(event, pano) {
      if (event) event.preventDefault();
      if (!pano) return;

      this.currentPano = this.$store.state.currentTour.panos.find(
        (p) => p.slug === pano.slug
      );
      window.krpanoObj.call(
        `loadscene('${pano.name}', null, MERGE, BLEND(0.5));`
      );

      // this.sendGtmView(
      //   `${this.currentPano.title} - Virtual tour`,
      //   `/${this.$locale}/virtual-tour/${this.currentTour.slug}/${this.currentPano.slug}`
      // );

      if (this.panorama !== pano.slug) {
        this.panorama = this.currentPano.slug;
        this.$router.push({
          path: `/${this.currentTour.slug}/${pano.slug}`,
        });
      }

      if (this.webVRActive) {
        addExitVrHotspot();
        addControlHotspots();
      }
    },
    async loadHotspotStyle(spot, hotspot, panorama) {
      hotspot.linkedPano = panorama;

      let template = `
        <div class="hotspot__wrapper">
          <div id="hotspot-${hotspot.id}"></div>
          <!-- <div class="hotspot__outer"></div> -->
        </div>
      `;

      // Set basic config
      spot.type = "text";
      spot.renderer = "css3d";
      spot.html = template;
      spot.zoom = false;
      spot.distorted = false;

      if (spot.sprite) {
        // Set the id of the moving hotspot
        spot.sprite.id = `hotspot__locator--${hotspot.id}`;

        // Init hotspot component onto template
        const HotspotCtor = Vue.extend(Hotspot);
        const hotspotElement = new HotspotCtor({
          propsData: {
            webVRActive: this.webVRActive,
            tour: this.currentTour,
            pano: this.currentPano,
            hotspot: hotspot,
          },
          parent: this,
        }).$mount(`#hotspot-${hotspot.id}`);

        // Set click action
        spot.onclick = () => {
          if (isTouchDevice()) {
            document.getElementById(hotspot.id).click();
          }
        };

        // Listen to hotspot
        hotspotElement.$on("navigate", (linkedPano) => {
          this.loadPano(null, linkedPano);
        });
        hotspotElement.$on("info", (content) => {
          this.modalContent = content;
          this.modal = true;
        });
      }
    },
    startWebVR() {
      if (!this.openHero) {
        addVrHotspots();
        if (this.firstVideoPano) {
          if (this.currentPano.id !== this.firstVideoPano.id) {
            addVideoHotspot(this.firstVideoPano);
          } else {
            addLeaveVideoHotspot(this.firstPano);
          }
        }
      }
      this.webVRActive = true;
      krpanoObj.call("webvr_onentervr();");
      krpanoObj.call("webvr.enterVR();");
      // this.sendGtmEvent("webvr-open", { webvr_pano: this.currentPano.title });
    },
    exitWebVR() {
      removeVrHotspots();
      this.webVRActive = false;
      krpanoObj.call("webvr.exitVR();");
      krpanoObj.call("webvr_onexitvr();");
      krpanoObj.call("lookto(0, 0)");
    },
    openFollowingTour(following = "next") {
      virtualTourApi
        .getPanosForFollowingTour(this.$store.state.currentTour.slug, following)
        .then((res) => {
          if (!res.data.panos) {
            window.location.href = `/${slug}`;
          } else {
            const tour = res.data;
            this.currentTour = tour;
            this.$store.state.currentTour = this.currentTour;
            this.panos = tour.panos;
            this.firstPano = this.panos.find((p) => p.type.value === "pano");
            this.firstVideoPano = this.panos.find(
              (p) => p.type.value === "video"
            );
            this.currentPano = tour.panos[0];
            krpanoObj.actions.loadpano(`/assets/vtours/${tour.slug}/tour.xml`);
          }
        });
    },
    openSlugTour(slug) {
      virtualTourApi.getPanosForTour(slug).then((res) => {
        if (!res.data.panos) {
          window.location.href = `/${slug}`;
        } else {
          const tour = res.data;
          this.currentTour = tour;
          this.$store.state.currentTour = this.currentTour;
          this.panos = tour.panos;
          this.firstPano = this.panos.find((p) => p.type.value === "pano");
          this.firstVideoPano = this.panos.find(
            (p) => p.type.value === "video"
          );
          this.currentPano = tour.panos[0];
          krpanoObj.actions.loadpano(`/assets/vtours/${tour.slug}/tour.xml`);
        }
      });
    },
  },
  metaInfo() {
    return {
      title: `${
        this.currentTour ? this.currentTour.title : "Virtuele tour"
      } - Sint-Niklaas`,
    };
  },
};
</script>

<style lang="scss">
@import "../scss/gui.scss";

.krpano__wrapper {
  background: $color-accent;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  div {
    padding: 0;
    margin: 0;
  }

  // CSS Krpano hack
  & #krpanoObject {
    & > div {
      & > div:last-child {
        & > div {
          // #hotspot__wrapper
          width: 0 !important;
          height: 0 !important;
          background-color: transparent !important;
          border: 1px solid transparent;

          &:hover,
          &.locator__touched {
            z-index: 2005 !important;
          }

          & > div {
            & > div {
              overflow: visible !important;
              width: 0 !important;
              height: 0 !important;

              & > div {
                margin: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

#krpano {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: transparent;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity $basic-transition-200 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.hotspot {
  &__outer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba($color-black, 0.5);
    width: 40px;
    height: 40px;
    z-index: -2;
    border-radius: 50%;

    transition: all $basic-transition-300;
  }

  &__wrapper:hover .hotspot__outer {
    width: 10px;
    height: 10px;
  }
}
</style>
