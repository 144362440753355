import axios from "axios";

const virtualTourApi = {
  getAllTours: async () => {
    return await axios.get(`${process.env.VUE_APP_API_URL}`);
  },
  getPanosForTour: async (slug) => {
    return await axios.get(`${process.env.VUE_APP_API_URL}?slug=${slug}`);
  },
  getPanosForFollowingTour: async (slug, following) => {
    return await axios.get(`${process.env.VUE_APP_API_URL}?slug=${slug}&key=${following}`);
  },
  getPano: async (vtourId, name) => {
    return await axios.get(
      `${process.env.VUE_APP_API_URL}/pano?vtourId=${vtourId}&name=${name}`
    );
  },
  getHotspot: async (vtourId, panoName, hotspotName) => {
    return await axios.get(
      `${process.env.VUE_APP_API_URL}/pano/hotspot?vtourId=${vtourId}&panoName=${panoName}&hotspotName=${hotspotName}`
    );
  },
};

export default virtualTourApi;
