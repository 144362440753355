<template>
  <div class="tutorial tutorial__overlay">
    <h2>{{ $page.tutorial_title }}</h2>
    <div class="tutorial__step tutorial__step-1 touch" v-if="isTouchDevice()">
      <img src="/assets/img/touch-pan.png" alt="Pan afbeelding" />
      <p>{{ $page.tutorial_drag_mobile }}</p>
    </div>
    <div class="tutorial__step tutorial__step-1 no-touch" v-else>
      <img src="/assets/img/mouse-pan.png" alt="Pan afbeelding" />
      <p>{{ $page.tutorial_drag }}</p>
    </div>

    <div class="tutorial__step tutorial__step-2 touch" v-if="isTouchDevice()">
      <img src="/assets/img/touch-zoom.png" alt="Zoom afbeelding" />
      <p>{{ $page.tutorial_zoom_mobile }}</p>
    </div>
    <div class="tutorial__step tutorial__step-2 no-touch" v-else>
      <img src="/assets/img/mouse-zoom.png" alt="Zoom afbeelding" />
      <p>{{ $page.tutorial_zoom }}</p>
    </div>

    <a
      id="tutorial__button"
      class="button button--outline button--outline-white"
      @click.prevent="$emit('close')"
    >
      {{ $page.tutorial_button }}
    </a>
  </div>
</template>

<script>
import { isTouchDevice } from "@/helpers";

export default {
  name: "TutorialScreen",
  methods: {
    isTouchDevice: isTouchDevice,
  },
  mounted() {
    localStorage.setItem("tutorialShown", true);
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/components/button.scss";

.tutorial {
  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    background-color: transparent;
    transition: opacity $basic-transition-200;

    // opacity: 0;
    background-color: rgba($color-black, 0.7);
    display: grid;
    place-content: center;
    text-align: center;
    z-index: 10000;
  }

  * {
    color: $color-white;
  }

  h2 {
    color: $color-secondary;
    font-weight: bold;
  }

  p {
    font-weight: bold;
  }

  .tutorial__step {
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      margin-right: 12px;
    }

    &-1 {
      margin: 56px 0 12px 0;
    }

    &-2 {
      margin: 12px 0 56px 0;
    }

    @include for-tablet-portrait-up {
      flex-direction: row;
    }
  }

  a {
    width: auto;
    margin-top: 56px;
    cursor: pointer;

    @include for-tablet-portrait-up {
      margin-top: 0;
    }
  }
}
</style>
