<template>
  <div class="video-interface">
    <div class="video-interface__actions">
      <div @click="togglePlay">
        <img v-if="playing" src="/assets/img/icon-pause.svg" alt="pause icon" />
        <img v-else src="/assets/img/icon-play.svg" alt="play icon" />
      </div>
      <div @click="toggleMute">
        <img v-if="muted" src="/assets/img/icon-unmute.svg" alt="unmute icon" />
        <img v-else src="/assets/img/icon-mute.svg" alt="mute icon" />
      </div>
    </div>
    <div class="video-interface__trackbar" @click="onSeek">
      <div class="seeker"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      playing: true,
      muted: false,
    };
  },
  mounted() {
    window.updateVideoProgress = (percentage) => {
      if (this.playing && percentage === "0%") this.playing = false;
      else this.playing = true;
      document.querySelector(".seeker").style.width = percentage;
    };
    window.playVideo = () => {
      this.togglePlay();
    };
    window.muteVideo = (muted) => {
      this.muted = muted;
    };
  },
  beforeDestroy() {
    window.updateTime = () => {};
    window.playVideo = () => {};
  },
  methods: {
    togglePlay() {
      if (krpanoObj) {
        if (this.playing) {
          krpanoObj.call("plugin[video].pause();");
          this.playing = false;
        } else {
          krpanoObj.call("plugin[video].play();");
          this.playing = true;
        }
      }
    },
    toggleMute() {
      if (krpanoObj) {
        if (this.muted) {
          krpanoObj.call("plugin[video].unmute();");
        } else {
          krpanoObj.call("plugin[video].mute();");
        }
        this.muted = !this.muted;
      }
    },
    onSeek(event) {
      if (krpanoObj) {
        const percentage =
          (event.clientX - event.target.offsetLeft) / event.target.offsetWidth;
        krpanoObj.call(`plugin[video].seek(${percentage});`);
        document.querySelector(".seeker").style.width = `${percentage}%`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/video.scss";
</style>
