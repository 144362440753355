import Vue from "vue";
import "./app.scss";
import App from "./App";
import store from "./store";
import router from "./router";
import VueMeta from "vue-meta";

Vue.config.productionTip = false;

Vue.use(VueMeta);

new Vue({
  el: "#app",
  store,
  router,
  delimiters: ["${", "}"],
  components: { App },
});
