const getTextWidth = (text, font) => {
  var canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement("canvas"));
  var context = canvas.getContext("2d");
  context.font = font;
  var metrics = context.measureText(text);
  return metrics.width;
};

const addExitVrHotspot = () => {
  // Create exit vr hotspot
  let spotName = `vr_exit`;
  let spot = krpanoObj.addhotspot(spotName);
  spot.loadstyle("vr_hotspot_style");
  spot.text = "Verlaat VR";
  spot.textwidth = getTextWidth("Verlaat VR", "bold 16px arial") + 64;
  spot.ath = 0;
  spot.atv = 80;
  spot.actiontype = "exit_vr";
};

const addControlHotspots = () => {
  // Create next tour project hotspot
  let nextSpotName = `vr_next`;
  let nextSpot = krpanoObj.addhotspot(nextSpotName);
  nextSpot.loadstyle("vr_hotspot_style");
  nextSpot.text = "Bekijk het volgende project";
  nextSpot.textwidth = getTextWidth("Volgend project", "bold 16px arial") + 64;
  nextSpot.ath = 0;
  nextSpot.atv = 75;
  nextSpot.actiontype = "next_vr";

  // Create previous tour project hotspot
  let prevSpotName = `vr_prev`;
  let prevSpot = krpanoObj.addhotspot(prevSpotName);
  prevSpot.loadstyle("vr_hotspot_style");
  prevSpot.text = "Bekijk het vorige project";
  prevSpot.textwidth = getTextWidth("Vorig project", "bold 16px arial") + 64;
  prevSpot.ath = 0;
  prevSpot.atv = 85;
  prevSpot.actiontype = "prev_vr";

  // Create aerial hotspot
  let aerialSpotName = `vr_aerial`;
  let aerialSpot = krpanoObj.addhotspot(aerialSpotName);
  aerialSpot.loadstyle("vr_hotspot_style");
  aerialSpot.text = "Ga naar het luchtbeeld";
  aerialSpot.textwidth =
    getTextWidth("Naar luchtbeeld", "bold 16px arial") + 64;
  aerialSpot.ath = 0;
  aerialSpot.atv = 315;
  aerialSpot.actiontype = "aerial_vr";
};

const addVideoHotspot = (videPano) => {
  // Create video hotspot
  let spotName = `vr_video`;
  let spot = krpanoObj.addhotspot(spotName);
  spot.loadstyle("vr_hotspot_style");
  spot.text = videPano.title;
  spot.textwidth = getTextWidth(videPano.title, "bold 16px arial") + 64;
  spot.ath = 0;
  spot.atv = 70;
  spot.actiontype = "navigation";
  spot.actionslug = videPano.slug;
  spot.actionname = videPano.name;
};

const addLeaveVideoHotspot = (firstPano) => {
  // Create leave video hotspot
  let spotName = `vr_first_pano`;
  let spot = krpanoObj.addhotspot(spotName);
  spot.loadstyle("vr_hotspot_style");
  spot.text = "Bekijk de 360° foto's";
  spot.textwidth = getTextWidth("Bekijk de 360° foto's", "bold 16px arial") + 64;
  spot.ath = 0;
  spot.atv = 70;
  spot.actiontype = "navigation";
  spot.actionslug = firstPano.slug;
  spot.actionname = firstPano.name;
};

const addSingleVrHotspot = (hotspot) => {
  // Create vr hotspot
  hotspot.classList.add("invisible");
  if (hotspot.nextElementSibling) {
    hotspot.nextElementSibling.classList.add("invisible");
  }
  let spotName = `vr_${hotspot.dataset.slug}`;
  let spot = krpanoObj.get(`hotspot["${spotName}"]`);

  if (hotspot.dataset.type == "navigation" && !spot) {
    spot = krpanoObj.addhotspot(spotName);
    spot.loadstyle("vr_hotspot_style");
    spot.text = hotspot.dataset.title;
    spot.textwidth =
      getTextWidth(hotspot.dataset.title, "bold 16px arial") + 64;
    spot.ath = hotspot.dataset.ath;
    spot.atv = hotspot.dataset.atv;
    spot.actiontype = "navigation";
    spot.actionslug = hotspot.dataset.slug;
    spot.actionname = hotspot.dataset.name;
  }
};

const addVrHotspots = () => {
  // Copy original hotspots
  addExitVrHotspot();
  addControlHotspots();
  let hotspots = document.querySelectorAll(".hotspot");
  hotspots.forEach((hotspot) => {
    addSingleVrHotspot(hotspot);
  });
};

const removeVrHotspots = () => {
  let hotspots = document.querySelectorAll(".hotspot");
  hotspots.forEach((hotspot) => {
    let spotName = `vr_${hotspot.dataset.slug}`;
    krpanoObj.removehotspot(spotName);
    krpanoObj.removehotspot(`vr_${hotspot.dataset.slug}_text`);
    krpanoObj.removehotspot(`vr_${hotspot.dataset.slug}_hitarea`);
    krpanoObj.removehotspot(`vr_${hotspot.dataset.slug}_hover`);
    hotspot.classList.remove("invisible");
    if (hotspot.nextElementSibling) {
      hotspot.nextElementSibling.classList.remove("invisible");
    }
  });

  // remove exit vr hotspot
  krpanoObj.removehotspot("vr_exit");
  krpanoObj.removehotspot(`vr_exit_text`);
  krpanoObj.removehotspot(`vr_exit_hitarea`);
  krpanoObj.removehotspot(`vr_exit_hover`);
  krpanoObj.removehotspot("vr_next");
  krpanoObj.removehotspot(`vr_next_text`);
  krpanoObj.removehotspot(`vr_next_hitarea`);
  krpanoObj.removehotspot(`vr_next_hover`);
  krpanoObj.removehotspot("vr_prev");
  krpanoObj.removehotspot(`vr_prev_text`);
  krpanoObj.removehotspot(`vr_prev_hitarea`);
  krpanoObj.removehotspot(`vr_prev_hover`);
  krpanoObj.removehotspot("vr_aerial");
  krpanoObj.removehotspot(`vr_aerial_text`);
  krpanoObj.removehotspot(`vr_aerial_hitarea`);
  krpanoObj.removehotspot(`vr_aerial_hover`);
  krpanoObj.removehotspot("vr_video");
  krpanoObj.removehotspot(`vr_video_text`);
  krpanoObj.removehotspot(`vr_video_hitarea`);
  krpanoObj.removehotspot(`vr_video_hover`);
  krpanoObj.removehotspot("vr_first_pano");
  krpanoObj.removehotspot(`vr_first_pano_text`);
  krpanoObj.removehotspot(`vr_first_pano_hitarea`);
  krpanoObj.removehotspot(`vr_first_pano_hover`);
};

const loadHeroHotspot = (spot) => {
  let template = `
      <div class="hotspot__wrapper">
        <div id="hotspot-${spot.name}" class="hotspot" data-title="${spot.title}" data-slug="${spot.linkedtour}" data-ath="${spot.ath}" data-atv="${spot.atv}">
          <div class="hotspot__inner">
            <h3 class="icon-link">
              ${spot.title} 
              <img class="icon icon--secondary" src="/assets/img/icon-arrow.svg" alt="Pijl icoon">
            </h3>
            <div class="hotspot__line">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <img class="hotspot__pin" src="/assets/img/icon-hotspot.png" />
          <div class="hotspot__outer"></div>
        </div>
      </div>
    `;

  // Set basic config
  spot.type = "text";
  spot.renderer = "css3d";
  spot.html = template;
  spot.zoom = false;
  spot.distorted = false;
  spot.clickCount = 0;

  spot.onclick = () => {
    let spots = krpanoObj.get("hotspot").getArray();
    spots.forEach((s) => {
      if (s !== spot) {
        s.clickCount = 0;
        document
          .getElementById(`hotspot-${s.name}`)
          .classList.remove("touched");
        document
          .getElementById(`hotspot__locator--${s.name}`)
          .classList.remove("locator__touched");
      }
    });

    if (isTouchDevice() && spot.clickCount < 1) {
      spot.clickCount++;
      document.getElementById(`hotspot-${spot.name}`).classList.add("touched");
      document
        .getElementById(`hotspot__locator--${spot.name}`)
        .classList.add("locator__touched");
    } else if (!isTouchDevice() || spot.clickCount == 1) {
      spot.clickCount = 0;
      window.location.href = `/${spot.linkedtour}`;
    }
  };

  if (spot.sprite) {
    spot.sprite.id = `hotspot__locator--${spot.name}`;
  }
};

function addHeroVrHotspots() {
  // Copy original hotspots
  let hotspots = document.querySelectorAll(".hotspot");
  hotspots.forEach((hotspot) => {
    hotspot.classList.add("invisible");

    // Check if tour has panos
    var http = new XMLHttpRequest();
    http.open("HEAD", `/assets/vtours/${hotspot.dataset.slug}/tour.xml`, false);
    http.send();

    if (http.status != 404) {
      let spotName = `vr_${hotspot.dataset.slug}`;
      let spot = krpanoObj.addhotspot(spotName);
      spot.loadstyle("vr_hotspot_style");
      spot.text = hotspot.dataset.title;
      spot.textwidth =
        getTextWidth(hotspot.dataset.title, "bold 16px arial") + 64;
      spot.ath = hotspot.dataset.ath;
      spot.atv = hotspot.dataset.atv;
      spot.actiontype = "navigation";
      spot.actionslug = hotspot.dataset.slug;
    }
  });

  // Create exit vr hotspot
  let spotName = `vr_exit`;
  let spot = krpanoObj.addhotspot(spotName);
  spot.loadstyle("vr_hotspot_style");
  spot.text = "Verlaat VR";
  spot.textwidth = getTextWidth("Verlaat VR", "bold 16px arial") + 64;
  spot.ath = 0;
  spot.atv = 90;
  spot.actiontype = "exit_vr";
}

export {
  addLeaveVideoHotspot,
  addVideoHotspot,
  addExitVrHotspot,
  addControlHotspots,
  addSingleVrHotspot,
  addVrHotspots,
  removeVrHotspots,
  loadHeroHotspot,
  addHeroVrHotspots,
};
