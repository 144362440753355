<template>
  <div class="dot__row" v-click-outside="handleClickOutside">
    <div
      v-for="(pano, index) in panos.filter((p) => p.type.value === 'pano')"
      :key="pano.slug"
      class="dot__container"
      @click="handleDotClick($event, index, pano, false)"
      @mouseout="handleMouseOut()"
      @mouseover="handleMouseOver(index)"
    >
      <div
        class="dot__thumb"
        :id="`thumb--${index}`"
        @click="handleDotClick($event, index, pano, true)"
      >
        <p class="dot__title">
          {{ $page.view }}<br /><strong>{{ pano.title }}</strong>
        </p>
        <div class="dot__thumb-overlay"></div>
        <img
          v-if="pano.thumbnailUrl"
          :src="
            pano.thumbnailUrl.includes('http')
              ? pano.thumbnailUrl
              : `/assets/vtours/${tour.slug}/${pano.thumbnailUrl}`
          "
          :alt="`Thumbnail for pano ${pano.slug}`"
        />
        <img
          v-else
          :src="`/assets/vtours/${tour.slug}/panos/${pano.name}.tiles/thumb.jpg`"
          :alt="`Thumbnail for pano ${pano.slug}`"
        />
      </div>
      <div
        :class="`dot ${
          active && active.slug == pano.slug ? 'dot--active' : ''
        }`"
      >
        <div class="dot__background"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { isTouchDevice } from "@/helpers";
import vClickOutside from "v-click-outside";

export default {
  name: "Dots",
  props: {
    panos: { default: [] },
    active: { default: null },
    loadPano: Function,
  },
  data() {
    return {
      title: null,
    };
  },
  computed: {
    tour() {
      return this.$store.state.currentTour;
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    handleLoadPano(event, pano) {
      if (this.active && this.active.slug != pano.slug) {
        this.closeAllThumbs();
        this.loadPano(event, pano);
      }
      this.$emit("close");
    },
    handleDotClick(event, index, pano, thumb) {
      if (
        isTouchDevice() &&
        document.getElementById(`thumb--${index}`).classList.contains("open") &&
        !thumb
      ) {
        this.handleLoadPano(event, pano);
      } else {
        this.closeAllThumbs();
        document.getElementById(`thumb--${index}`).classList.add("open");
      }

      if (!isTouchDevice()) {
        this.handleLoadPano(event, pano);
      }
    },
    closeAllThumbs() {
      let dots = document.querySelectorAll(".dot__thumb");
      dots.forEach((dot) => {
        dot.classList.remove("open");
      });
    },
    handleMouseOver(index) {
      if (!isTouchDevice()) {
        this.closeAllThumbs();
        document.getElementById(`thumb--${index}`).classList.add("open");
      }
    },
    handleMouseOut() {
      if (!isTouchDevice()) {
        this.closeAllThumbs();
      }
    },
    handleClickOutside() {
      if (isTouchDevice()) {
        this.closeAllThumbs();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dot {
  display: block;
  width: 10px;
  height: 10px;
  background-color: $color-white;
  border-radius: 50%;
  position: relative;

  display: grid;
  place-content: center;

  transition: all $basic-transition-200;
  pointer-events: none;

  &__background {
    width: 0;
    height: 0;
    background-color: $color-primary;
    border-radius: 50%;

    transition: all $basic-transition-200;
  }

  &--active {
    width: 18px;
    height: 18px;

    .dot__background {
      width: 12px;
      height: 12px;
      background-color: $color-primary;
      border-radius: 50%;

      transition: all $basic-transition-200;
    }

    @include for-tablet-landscape-up {
      width: 24px;
      height: 24px;

      .dot__background {
        width: 18px;
        height: 18px;
      }
    }
  }

  &__container {
    margin: 3px 0;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: grid;
    place-content: center;
    pointer-events: all;
    position: relative;

    &:hover .dot:not(.dot--active) {
      width: 18px;
      height: 18px;

      .dot__background {
        width: 12px;
        height: 12px;
      }
    }

    @include for-tablet-landscape-up {
      margin: 12px 0;

      &:hover .dot:not(.dot--active) {
        width: 24px;
        height: 24px;

        .dot__background {
          width: 18px;
          height: 18px;
        }
      }
    }

    // &:hover .dot__thumb {
    //   right: 40px;
    //   opacity: 1;
    //   pointer-events: all;
    // }
  }

  &__row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    min-height: 500px;
  }

  &__title {
    z-index: 1;
    color: $color-text-inverse;
    // font-weight: bold;
    font-size: 14px;

    position: absolute;
    top: 50%;
    left: 48%;
    transform: translate(-50%, -50%);

    text-align: center;
    width: 80%;
  }

  &__thumb {
    display: block;
    // display: none;
    pointer-events: none;
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);

    width: 200px;
    height: 125px;
    overflow: hidden;
    box-shadow: 0 0 32px 8px rgba(0, 0, 0, 0.2);

    opacity: 0;
    transition: all $basic-transition-300;

    // Tooltip-like path
    clip-path: polygon(
      0% 0%,
      93% 0%,
      93% 42%,
      100% 50%,
      93% 58%,
      93% 100%,
      0% 100%
    );

    // @include for-tablet--up {
    //   display: block;
    // }

    * {
      pointer-events: none;
    }

    p {
      font-size: 1.5rem;
      line-height: 2.4rem;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &.open {
      right: 40px;
      opacity: 1;
      pointer-events: all;
    }

    &-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(lighten($color-black, 10%), 0.3);
    }
  }
}
</style>
