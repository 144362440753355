<template>
  <div
    :class="`hotspot ${`hotspot__${hotspot.type.value}`} ${
      clickCount > 0 && isTouchDevice ? 'touched' : ''
    }`"
    :id="hotspot.id"
    :data-title="hotspot.linkedPano ? hotspot.linkedPano.title : ''"
    :data-slug="hotspot.linkedPano ? hotspot.linkedPano.slug : ''"
    :data-name="hotspot.linkedPano ? hotspot.linkedPano.name : ''"
    :data-ath="hotspot.ath"
    :data-atv="hotspot.atv"
    :data-type="hotspot.type.value"
    @click="handleClick"
    v-click-outside="handleClickOutside"
  >
    <div :class="`hotspot__inner hotspot__inner__${hotspot.type.value}`">
      <img
        v-if="hotspot.type.value === 'info'"
        class="icon--primary"
        :src="getIcon()"
        alt="hotspot icon"
      />

      <h3 v-else-if="hotspot.type.value === 'navigation'" class="icon-link">
        {{ hotspot.linkedPano.title }}
        <img
          class="icon icon--secondary"
          src="/assets/img/icon-arrow.svg"
          alt="Pijl icoon"
        />
      </h3>
      <img
        v-else
        class="icon--primary"
        :src="`/assets/img/icon-${hotspot.type.value}.svg`"
        alt="hotspot icon"
      />
      <div class="hotspot__line" v-if="hotspot.type.value === 'navigation'">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>

    <img
      class="hotspot__pin"
      src="/assets/img/icon-hotspot.png"
      v-if="hotspot.type.value === 'navigation'"
    />
    <div
      class="hotspot__outer"
      v-if="hotspot.type.value === 'navigation'"
    ></div>
  </div>
</template>

<script>
import { isTouchDevice } from "@/helpers";
import { addSingleVrHotspot } from "@/helpers/vr";
import vClickOutside from "v-click-outside";

export default {
  name: "Hotspot",
  props: {
    webVRActive: Boolean,
    tour: Object,
    pano: Object,
    hotspot: Object,
    clickedOutside: Boolean,
  },
  data() {
    return {
      isTouchDevice: isTouchDevice(),
      clickCount: 0,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mounted() {
    let hotspotDOM = document.getElementById(this.hotspot.id);
    if (this.webVRActive && hotspotDOM) {
      addSingleVrHotspot(hotspotDOM);
    }
  },
  methods: {
    getIcon() {
      if (this.hotspot.modal.hasVideo) return "/assets/img/icon-info-video.svg";
      if (this.hotspot.modal.images) return "/assets/img/icon-info-gallery.svg";
      return "/assets/img/icon-info.svg";
    },
    handleClickOutside(event) {
      this.clickCount = 0;
      if (
        document.getElementById(this.hotspot.id) &&
        document
          .getElementById(this.hotspot.id)
          .classList.contains("hotspot__small-info")
      ) {
        document.getElementById(this.hotspot.id).classList.remove("touched");
      }
    },
    updateClasses() {
      document.querySelectorAll(".hotspot__small-info").forEach((el) => {
        el.classList.remove("touched");
      });
      document.querySelectorAll(".locator__touched").forEach((el) => {
        el.classList.remove("locator__touched");
      });
      document
        .getElementById(`hotspot__locator--${this.hotspot.id}`)
        .classList.add("locator__touched");
    },
    handleClick() {
      this.updateClasses();
      this.clickCount++;

      if (
        this.isTouchDevice &&
        this.clickCount < 2 &&
        this.hotspot.type.value !== "small-info"
      )
        return;

      let data = {
        hotspot_type: this.hotspot.type.value,
      };

      switch (this.hotspot.type.value) {
        case "navigation":
          this.$emit("navigate", this.hotspot.linkedPano);
          data.nav_from = this.pano.title;
          data.nav_to = this.hotspot.linkedPano.title;
          break;
        case "info":
          this.$emit("info", this.hotspot.modal);
          data.modal_on = this.pano.title;
          data.modal_title = this.hotspot.modal.title;
          data.modal_type = this.hotspot.modal.hasVideo
            ? "Video modal"
            : this.hotspot.modal.images && this.hotspot.modal.images.length > 0
            ? "Image modal"
            : "Text modal";
          break;
        case "small-info":
          const smallInfo = document.getElementById(this.hotspot.id);
          if (!smallInfo.classList.contains("touched")) {
            document.getElementById(this.hotspot.id).classList.add("touched");
          }
          break;
        default:
          break;
      }

      // Fire GTM event for click
      // if (data) {
      //   this.sendGtmEvent("click-hotspot", data, this.tour.title, this.pano.title);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
$hotspot-dimension: 4.4rem;
$hotspot-dimension--hovered: 5.2rem;

.hotspot {
  /*
    Position on ath/atv : krpano hack
  */
  position: absolute;
  cursor: pointer !important;
  pointer-events: all;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: $hotspot-dimension;
  height: $hotspot-dimension;
  border-radius: 50px;

  display: grid;
  place-content: center;

  transition: all $basic-transition-200;

  &:not(.hotspot__navigation) {
    background: $color-accent;
    border: 0.2rem solid $color-white;
    box-shadow: 0 0 32px 8px rgba(0, 0, 0, 0.2);
  }

  &__inner {
    &__navigation {
      position: relative;
      pointer-events: none;
    }

    &__info,
    &__small-info {
      width: $hotspot-dimension;
      height: $hotspot-dimension;
      border-radius: 50px;

      img {
        // opacity: 0;
        height: 100%;
        width: 100%;
        transition: all $basic-transition-200;
        transform: scale(0.8);
      }
    }

    h3 {
      position: absolute;
      background-color: $color-primary;
      color: $color-text-inverse;
      padding: 6px 8px 6px 24px;
      border-radius: 50px;
      box-shadow: 0 6px 16px 6px rgba(0, 0, 0, 0.2);
      top: -50px;
      left: 50%;
      transform: translate(-50%, -50%);

      font-size: 1.4rem;
      font-weight: 600;
      line-height: 18px;
      z-index: 1;

      transition: all $basic-transition-200;
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.7);

      .icon {
        margin: 0 0 0 8px;
        transform: scale(0.8);
      }
    }
  }

  &__pin {
    max-width: 28px;
    // filter: invert(67%) sepia(75%) saturate(630%) hue-rotate(354deg)
    //   brightness(96%) contrast(113%)
    //   drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.3));
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(3);
    transition: transform $basic-transition-200;
  }

  &__outer {
    background: $color-white !important;

    width: 30px !important;
    height: 10px !important;

    position: absolute;
    top: unset !important;
    bottom: -10px !important;
  }

  &__line {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 4px;

    top: -40px;
    position: absolute;
    left: -50%;
    transform: translate(-50%, -40%);
    opacity: 0;
    transition: all $basic-transition-200;

    div {
      background-color: $color-white;
      height: 4px;
      width: 100%;
      margin: 3px 0 !important;
    }
  }

  &:hover,
  &.touched {
    &:not(.hotspot__navigation) {
      width: $hotspot-dimension--hovered;
      height: $hotspot-dimension--hovered;
    }

    .hotspot__pin {
      transform: translate(-50%, -40%) scale(2.8);
    }
  }
}
</style>
