<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "App",
  props: ["page"],
  created() {
    Vue.prototype.$page = this.page;
  },
};
</script>
